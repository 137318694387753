export const langList = [
    { label: "English", value: 'en_US', info: 'en' },
    { label: "中文", value: 'zh_CN', info: 'cn'},
]

export const defaultLang = langList[0];

// get current language object
export const getLangObject = ()=>{
    const local = window.localStorage.getItem('LANGUAGE');
    if(!local){
        return defaultLang;
    }
    let lang = langList.find((item) => item.value == local);
    if (!lang) {
        lang = defaultLang;
    }
    return lang;
}

export const switchLang = (lang) => {
    window.localStorage.setItem('LANGUAGE', lang);
    window.location.reload();
};