import Web3 from 'web3';
import ERC20 from '@/config/abi/ERC20.json';
import Bridge from '@/config/abi/Bridge.json';

export const queryBalanceOf = async (wallet, contractAddress, account = wallet.account) => {
    var web3 = wallet.library && wallet.library.provider ? new Web3(wallet.library.provider) : null;
    if (!web3) return '0';
    let contract = new web3.eth.Contract(ERC20, contractAddress);
    try {
        const balance = await contract.methods.balanceOf(account).call();
        console.log("queryBalanceOf: ", balance)
        return balance;
    } catch (error) {
        console.error('queryBalanceOf: ', error);
    }
}

export const getAllowance = async (wallet, contractAddress, account, spender) => {
    var web3 = wallet.library && wallet.library.provider ? new Web3(wallet.library.provider) : null;
    if (!web3) return '0';
    let contract = new web3.eth.Contract(ERC20, contractAddress);
    try {
        const balance = await contract.methods.allowance(account, spender).call();
        console.log("getAllowance: ", balance)
        return balance;
    } catch (error) {
        console.error('getAllowance: ', error);
    }
}

export const approve = (wallet, contractAddress, spender) => {
    var web3 = wallet.library && wallet.library.provider ? new Web3(wallet.library.provider) : null;
    let contract = new web3.eth.Contract(ERC20, contractAddress);
    console.log(`approve tokenContractAddress: ${contractAddress}, spender: ${spender}`);
    return contract.methods
    .approve(spender, '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
    .send({ from: wallet.account })
    .on('error', function (error) {})
    .on('transactionHash', function (hash) {
      console.log('approve transactionHash: ', hash);
    })
    .on('receipt', (receipt) => {
      console.log('approve receipt: ', receipt);
    });
}

export const deposit = (wallet, contractAddress, tokenAddr, toContractAddr, userAddr, amount, fee) => {
    var web3 = wallet.library && wallet.library.provider ? new Web3(wallet.library.provider) : null;
    let contract = new web3.eth.Contract(Bridge, contractAddress);
    console.log(`deposit contractAddress: ${contractAddress}, tokenAddr: ${tokenAddr}, toContractAddr: ${toContractAddr}, userAddr: ${userAddr}, amount: ${amount}`);
    return contract.methods
    .deposit(tokenAddr, toContractAddr, userAddr, amount)
    .send({ from: wallet.account, value: fee })
    .on('error', function (error) {})
    .on('transactionHash', function (hash) {
      console.log('deposit transactionHash: ', hash);
    })
    .on('receipt', (receipt) => {
      console.log('deposit receipt: ', receipt);
    });
}