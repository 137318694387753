import types from '../types'

const defaultState = {
    visible: false,
    active: false,
    account: '',
    chainId: '',
    defaultChainId: '',
    env: '',
    networkName: '',
    chainType: '',
    network: {},
    chainInfo: {
        currentChain:{},
        contractAddress: {},
        contractAlias: {},
        currentTradeCoin: [],
        usableUrl: {},
        supportChainArray: [],
    },
};

const wallet = (state = defaultState, action) => {
    switch (action.type) {
        case types.Wallet_Modal:
            var newState = {
                ...state,
                ...action.info
            }
            if (!newState.active) {
                newState.account = ''
                newState.network = ''
                newState.chainId = ''
            }
            return newState;
        default:
            return state
    }
};

export default wallet;
