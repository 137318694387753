import axios from 'axios';
import qs from 'qs';
import { Spin ,message} from 'antd';


const interceptors = (instance,params) => {

    instance.interceptors.request.use(
        (config) => config,
        (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(response => {

        if (process.env.NODE_ENV === 'development') console.info(`api: ${response.config.url}response:`, response);
        message.destroy();
        const { code, msg } = response.data;

        if (!code || code === '0') {
            return Promise.resolve( response.data|| '');
        } else if (code === 200) {
            return Promise.resolve(response.data.data || '');
        } else {
            return Promise.reject(Object.assign({}, { msg: code || msg, code }));
        }

    },error => {

        try {
            if (process.env.NODE_ENV === 'development') console.error(`api${error.response.config.url}error info:`, error.response);
            const code = error.response.status || '';
            const msg = error.response.data.msg;
            return Promise.reject(Object.assign({}, { code, msg: code || msg || 'system andling exceptions.' }));
        } catch (e) {
            // return Promise.reject(Object.assign({}, { msg: i18next.t('-1'), code: -1 }));
            return Promise.reject(Object.assign({}, { msg: '-1', code: -1 }));
        }

    });
};

/**
 * @param {*} url
 * @param {*} params
 */
export function query(url, params, type='get') {
    const apiUrl = joinUrl(url);

    const instance = axios.create({ timeout: 10000, headers: { 'Content-Type': 'application/x-www-form-urlencoded', Accept: 'application/json' } });
    interceptors(instance,params);

    let paramsTemp = {};
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            paramsTemp[key] = element;
        }
    }

    const hide= message.open({
        content:<Spin className="waiting" tip="Loading..."></Spin>,
        className:'waiting',
        duration:1000
    })
    hide()
    paramsTemp = { p: window.JSON.stringify(paramsTemp) };

    if(type === 'get'){
        return instance.get(apiUrl + '?' + qs.stringify(paramsTemp));
    }else{
        return instance.get(apiUrl, qs.stringify(paramsTemp));
    }
}

function joinUrl(path) {
    var baseUrl = getBaseUrl();
    if (path && !path.startsWith('/')) {
        path = '/' + path;
    }
    return baseUrl + (path || '');
}

function getBaseUrl() {
    if (window.location.host == 'www.crossing.network') {
        return 'https://www.crossing.network';
    }
    if (window.location.host == 'crossing.network') {
        return 'https://crossing.network';
    }
    return 'https://test.crossing.network';
}