import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AppRouter from './routers';
import store from './store';

import './i18n';
import './styles/global.scss';


import reportWebVitals from './reportWebVitals';
import vconsole from 'vconsole';

const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 15000;
    return library;
};

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <Provider store={store}>
            <AppRouter />
        </Provider>
    </Web3ReactProvider>,
    document.getElementById('root')
);

// new vconsole()
// if (process.env.NODE_ENV === 'development'){
//     new vconsole()
// }

reportWebVitals();
