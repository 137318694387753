
import types from '../types'

/** 钱包modal控制
 * @param {*} visible
 */
export const actionWalletModal = (info) => {
    return (dispatch) => {
        dispatch({ 'type': types.Wallet_Modal, 'info': {'visible': info} });
    };
};

export const actionWalletLogin = (info) => {
    return (dispatch) => {
        dispatch({type: types.Wallet_Modal,info});
    };
};

export const actionWalletLogout = (info) => {
    return (dispatch) => {
        dispatch({type: types.Wallet_Modal,info});
    };
};
