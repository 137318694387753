import Chain from './chain/chain.config';
import Interface from './interface/interface.config';
//import Contract from './contract/contract.config';
//import coinsToken from './contract/coin.config';


// default chainId
export const defaultChainId = 1;

// available set of chain array
//export const supportChainArray = [1,3,56,97,256];

export const supportChainArray = Object.keys(Chain).map((item) => parseInt(item)).filter((item) => !isNaN(item));

export class ChainConfig {
    constructor(chainId){
        // default chainId
        this.defaultChainId = defaultChainId;
        // current chainId, if the current chainId does not exist, use the default chainId
        this.chainId = chainId || defaultChainId;
        this.isSupportChain = supportChainArray.includes(this.chainId);
        this.supportChainArray = this.getUsableChain();
        this.currentChain = this.getChainInfo(this.chainId);
        this.usableUrl = this.getUsableUrl();
        this.chainList = Chain;
    }

    // 获取chain 对象
    getChainInfo(chainId){
        const usable = this.supportChainArray;
        return usable[chainId] ? Object.assign({}, usable[chainId]) : {};
    }

    // 获取可用chain array对象
    getUsableChain(){
        const obj = {};
        supportChainArray.forEach((item)=>{
            if(Chain[item] !== undefined){
                obj[item] = Chain[item];
            }
        });
        return obj;
    }

    // 获取可用chain rpc url 对象
    getUsableUrl(){
        let url = {};
        Object.keys(this.supportChainArray).forEach(key=>{
            url[key] = this.supportChainArray[key]['rpcUrl'];
        })
        return url;
    }
}
