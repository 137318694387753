/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import ismobile from 'ismobilejs';
import { injected, walletconnect, walletlinkconnect, bscconnect, isWalletlinkSupported, isBscSupported } from './connectors';
import { ChainConfig } from '@/config';
import { actionWalletModal,actionWalletLogin } from '@/store/actions/wallet';
import { useEagerConnect, useInactiveListener } from './listener';
import { Modal } from 'antd';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import './wallet.scss';
const { chainList } = new ChainConfig();

const Wallet = () => {
    const [isMobile,setIsMobile] = useState(ismobile(window.navigator).phone);
    const walletList = [
        {
            label: 'MetaMask',
            iconImage: '/images/wallet/metamask.png',
            instance: injected,
            isVisible: () => {
                return window.ethereum;
            },
        },
        {
            label: 'WalletConnet',
            iconImage: '/images/wallet/walletconnet.png',
            instance: walletconnect,
            isVisible: () => {
                return true;
            },
        },
        {
            label: 'Coinbase Wallet',
            iconImage: '/images/wallet/coinbaseWalletIcon.svg',
            instance: walletlinkconnect,
            isVisible: () => {
                //return isMobile;
                return true;
            },
        },
        {
            label: 'Binance Chain Wallet',
            iconImage: '/images/wallet/binance.png',
            instance: bscconnect,
            isVisible: () => {
                return isBscSupported() && window.BinanceChain;
            },
        },
        {
            label: 'MathWallet',
            iconImage: '/images/wallet/mathWallet.png',
            instance: injected,
            isVisible: () => {
                return window.ethereum;
            },
        },
        {
            label: 'Coin 98 Wallet',
            iconImage: '/images/wallet/icon_coin98.png',
            instance: injected,
            isVisible: () => {
                return window.ethereum;
            },
        },
    ]

    const dispatch = useDispatch();
    const { wallet } = useSelector((state) => state);
    const storeData = useSelector((state) => state.wallet);

    const web3 = useWeb3React()
    const { active, account, chainId, connector, library, activate } = web3
    const [activatingConnector, setActivatingConnector] = useState();

    // 监听是否登录钱包
    const triedEager = useEagerConnect();
    useInactiveListener(!triedEager || !!activatingConnector);

    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
        updateWallet(1);
    }, [activatingConnector, connector]);

    // 监听account 是否发生变化，如退出，切换钱包
    useEffect(()=>{
        updateWallet(2)
    }, [account, chainId, web3]);

    useEffect(()=>{
        if(!storeData.visible) return;
        if(!isMobile) return;
        if(!window.ethereum) return;
        changeWallet(injected);
    },[storeData])

    // 更新钱包登录状态
    const updateWallet = (type1) => {
        if (account === storeData.account && chainId === storeData.chainId){
            return 0;
        }

        const config = new ChainConfig(chainId);
        const chainInfo = config.currentChain

        actionWalletLogin({
            visible: false,
            active: active,
            account: account,
            chainId: chainId,
            env: chainInfo.env,
            networkName: chainInfo.networkName,
            chainType: chainInfo.type,
            network: chainInfo,
            library: library,
            chainInfo: config
        })(dispatch)
    }

    // 打开链接操作
    const changeWallet = (createInstance)=>{
        if (createInstance == walletconnect && chainList[wallet.defaultChainId]) {
            createInstance = new WalletConnectConnector({
                rpc: { [wallet.defaultChainId]: [chainList[wallet.defaultChainId].rpcUrl] },
                bridge: 'https://bridge.walletconnect.org',
                qrcode: true,
                pollingInterval: 15000,
            });
        }
        setActivatingConnector(createInstance);
        activate(createInstance);
    }

    return (
        <>
        {
            (
                !isMobile
                || !window.ethereum
            ) &&
            <Modal visible={storeData.visible} maskClosable={false} centered onCancel={(e)=>{ actionWalletModal(false)(dispatch) }} className='walletSelect' title="Select a wallet supplier" footer={null} width={343}>
                <div className="wallet-connect">
                    {
                        walletList.map(v=>{
                            return v.isVisible() && (
                                <div className="row" onClick={()=>{changeWallet(v.instance)}} key={v.label}>
                                    <span>{v.label}</span>
                                    <img src={v.iconImage} alt=""/>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        }

            {/* <Modal visible={storeData.active && !storeData.network.type} closable={false} maskClosable={false} centered className='walletSelects' title="Wrong network" header={null} footer={null} width={220}>
                <div className="modal_content">
                    <img src="/images/icon_attention.png" alt=""/>
                    <p>Please switch to ETH Main</p>
                </div>
            </Modal> */}
        </>
    );
};

export default Wallet;



