import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { langList, getLangObject, switchLang } from '@/i18n/langUtil';

import '../style.scss';

const { Option } = Select;
const HeaderLang = ()=>{
    const [lang, setLang] = useState('')

    useEffect(() => {
        setLang(getLangObject().value);
    }, []);

    return (
        <div className="HeaderLanuage">
            <Select  
            value={lang}
            onChange={e=>{
                setLang(e);
                switchLang(e);
            }}
            dropdownClassName="header"
            style={{lineHeight:"42px"}}>
                {langList.map((v,i)=>{
                    return (
                        <Option key={v.value} value={v.value}>
                            {v.label}
                        </Option>
                    )
                })}
            </Select>
        </div>
    )
}

export default HeaderLang;

