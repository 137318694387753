import types from '../types'

import common from '@/utils/Common';

const userlogin = common.storage('userAuthorize') ? false : true;

const defaultState = {
    pawnRepayDialog: false,
    valuationBuyDialog: false,
    valuationNtfsBuyDialog: false,
    marketRentDialog: false,
    marketWaitpayDialog: false,
    allHistoryDialog: false,
    userLoginDialog: userlogin,
    infoData: {},
};

const modal = (state = defaultState, action) => {
    switch (action.type) {
        case types.Modal_handle:
            return {
                ...state,
                ...action.info
            }
        default:
            return state
    }
};

export default modal;
