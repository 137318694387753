import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import './crossModal.scss';
import { Input, Space,Modal } from 'antd';

const { Search } = Input;
const CrossModal = (props) => {
  const { t } = useTranslation();
  const { visible, title, evtCancel, evtConfirm } = props;
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);

  useEffect(()=>{
    var array = [];
    (props.data || []).forEach(token => {
      if (keyword && keyword.length) {
        // var k = keyword.toLowerCase();
        // if (token.tokenName.toLowerCase().includes(k)) {
        //   var chainArray = (token.data || []).map((item) => Object.assign({tokenName: token.tokenName, tokenIcon: token.icon}, item));
        //   array = array.concat(chainArray);
        // } else {
        //   var chainArray = (token.data || []).filter((item) => item.tokenAddr.toLowerCase().includes(k) || item.chainName.toLowerCase().includes(k))
        //   chainArray = chainArray.map((item) => Object.assign({tokenName: token.tokenName, tokenIcon: token.icon}, item));
        //   array = array.concat(chainArray);
        // }
        var k = keyword.toLowerCase();
        if (token.tokenName.toLowerCase().includes(k)) {
          array.push(token);
        } else {
          var chainInfo = (token.data || []).find((item) => item.tokenAddr.toLowerCase() == k);
          if (chainInfo) {
            array.push({...token, chainId: chainInfo.chainId});
          }
        }
      } else {
        // var chainArray = (token.data || []).map((item) => Object.assign({tokenName: token.tokenName, tokenIcon: token.icon}, item));
        // array = array.concat(chainArray);
        array.push(token);
      }
    });
    setList(array);
  },[props.data,keyword])

  //console.log(list);

  list.forEach((item) => console.log);

  const onSearch = (value) => {
    console.log(value);
    setKeyword(value);
  };

  return (
    <Modal visible={props.visible} className="cross-modal" onCancel={props.onCancel} transparent footer={null} width={428} centered title={t('select_token')}>
      <Search placeholder={t('search_hint')} onChange={e=> onSearch(e.target.value)} />
      <div className="cross_modal_content">
        {
          list.length > 0 ?
            list.map((item) => (
            //   <div className="cross_modal_list" key={item.tokenName + item.chainId} onClick={e=> props.onSelectToken(item.tokenName, item.chainId)}>
            //   <img src={item.tokenIcon} alt=""/>
            //   <div >
            //     <span>{item.tokenName}-{item.chainName}</span>
            //     <span>{item.tokenAddr}</span>
            //   </div>
            // </div>
              <div className="cross_modal_list" key={item.tokenName} onClick={e=> props.onSelectToken(item.tokenName, item.chainId)}>
                <img src={item.icon} alt=""/>
                <div >
                  <span>{item.tokenName}</span>
                </div>
              </div>
            ))
            :
            <div className="listNone">
              {t('net_support_token')}
            </div>
        }
      </div>
    </Modal>
  );
};

export default CrossModal;
