import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from '@web3-react/network-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { BscConnector } from '@binance-chain/bsc-connector'

import { defaultChainId, supportChainArray, ChainConfig } from '@/config'
const { currentChain, usableUrl, chainList } = new ChainConfig();
//import Chain from '@/config/chain/chain.config';

// metamask mathwallet
export const isInjectedSupported = () => {
    //  return chainConfig.supportChainArray.length > 0;
    return true;
}

export const isNetworkConnectorSupported = () => {
    return true;
}

export const isWalletconnectSupported = () => {
    return true;
}

export const isWalletlinkSupported = () => {
    return supportChainArray.includes(1);
}

export const isBscSupported = () => {
    return supportChainArray.includes(56) || supportChainArray.includes(97);
}

export const injected = new InjectedConnector({ supportedChainIds: Object.keys(chainList).map((item) => parseInt(item)).filter((item) => !isNaN(item))});

export const network = new NetworkConnector({
    urls: usableUrl,
    defaultChainId,
})

export const walletconnect = new WalletConnectConnector({
    rpc: { [defaultChainId]: [currentChain.rpcUrl] },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    pollingInterval: 15000,
});

export const walletlinkconnect = new WalletLinkConnector({
    appName: window.location.origin,
    url: currentChain.rpcUrl,
})

export const bscconnect = new BscConnector({
    supportedChainIds: [56, 97]
})