import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';

import WalletConnect from '../views/commonModule/wallet';
import Home from '../views/home/';

const AppRouter = () => {
    return (
        <Router>
            <Suspense
                fallback={
                    <div className="global_loading">
                        <Spin tip="Loading..." />
                    </div>
                }>
                <div className="page">
                    <Switch>
                        <Route path="/" component={Home} />
                    </Switch>
                </div>
            </Suspense>
            <WalletConnect />
        </Router>
    );
};

export default AppRouter;
