import { Decimal } from 'decimal.js';
import * as web3utils from 'web3-utils';

Decimal.set({ precision: 50, toExpNeg: -30, toExpPos: 30 });

export function fromWei(num, decimal = 18) {
    switch(Number(decimal)){
        case 18:
            return web3utils.fromWei(num);
        default:
            return div(num, Math.pow(10, decimal)).valueOf();
    }
}

export function toWei(num, decimal = 18) {
    switch(Number(decimal)){
        case 18:
            return web3utils.toWei(num);
        default:
            return mul(num, Math.pow(10, decimal)).valueOf();
    }
}


export function toFixed(num, dec = 2,type='floor') {
    const p = pow(10,dec);
    const l = mul(num,p);
    const c = type==='floor' ? floor(l) : ceil(l);
    const m = div(c,p);
  return m;
}

export function toNumber(val) {
  return Decimal(val).toNumber();
}

export function sub(v1, v2) {
  return Decimal.sub(v1, v2).valueOf();
}

export function add(v1, v2) {
  return Decimal.add(v1, v2).valueOf();
}

export function mul(v1, v2) {
  return Decimal.mul(v1, v2).valueOf();
}

export function div(v1, v2) {
  return Decimal.div(v1,v2).valueOf();
}

export function EQ(val, compareVal) {
  return Decimal(val).eq(compareVal);
}

export function GT(val, compareVal) {
  return Decimal(val).gt(compareVal);
}

export function GE(val, compareVal) {
  return Decimal(val).gte(compareVal);
}

export function LT(val, compareVal) {
  return Decimal(val).lt(compareVal);
}

export function LE(val, compareVal) {
  return Decimal(val).lte(compareVal);
}

export function pow(x,y){
    return Decimal.pow(x,y).valueOf();
}

export function ceil(num){
    return Decimal.ceil(num).valueOf();
}

export function floor(num){
    return Decimal.floor(num).valueOf();
}

/**
 * 科学计数法转换
 * @param {*} val
 */
 export function numToStr(val) {
  return Decimal(val).valueOf();
}

export function numFmt(val, dn = 2) {
  let obj = String(val);
  // 修复第一个字符是小数点 的情况.
  if (obj !== '' && obj.substring(0, 1) === '.') obj = '0.';
  obj = obj.replace(/[^\d.]/g, ''); // 清除“数字”和“.”以外的字符
  obj = obj.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
  obj = obj.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
  const str = new RegExp(`^(-)*(\\d+)\\.(\\d{${dn}}).*$`);
  obj = obj.replace(str, '$1$2.$3'); // 只能输入两个小数
  if (dn === 0) {
    obj = numToStr(parseFloat(obj));
  } else if (obj.indexOf('.') < 0) {
    // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
    obj = numToStr(parseFloat(obj));
  }

  return isNaN(obj) ? 0 : obj;
}


// 转科学计数
// >=1，截6位小数
// ＜1，起始3个0，使用科学计数法。最多截6位小数
export function fmtToExponential(value){

  if(value==='0' || !value) return 0;

  const Dec = Decimal(value);

  if(value >= 1){
      if( Dec.isInteger() ){
          return Dec.toString()
      }
      return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
  }

  if(Dec.e < -3){
      return Dec.toExponential(6, Decimal.ROUND_DOWN);
  }

  return parseFloat( Dec.toFixed(6, Decimal.ROUND_DOWN) );
}

export const getURLParams = (name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};