import { useEffect, useState ,useRef,useContext} from "react"
import { useSelector,useDispatch } from 'react-redux';
import { actionWalletModal,actionWalletLogin,actionWalletLogout } from '@/store/actions/wallet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, message,Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ButtonBox } from '@/components/ui'
import * as Tools from '@/utils/Tools';
import CrossModal from '../commonModule/crossModal/crossModal'
// import AppContext from '@/state';
import TransferForm from './subModule/transferForm';
//import CrossChainButton from './subModule/submit';
import { DownOutlined } from '@ant-design/icons';
import './style.scss';
import MockJSON from './mock.json';
import ChainConfigInfo from "@/config/chain/chain.config";
import { queryBalanceOf, getAllowance, approve, deposit } from '@/service';
import { fromWei, toWei } from 'web3-utils';
import * as request from '@/service/interface/request';
import HeaderLang from "./subModule/header"
import { langList, getLangObject, switchLang } from '@/i18n/langUtil';
import Web3 from 'web3';

const { Option } = Select;
const Exchange = () => {
    const { wallet } = useSelector((state) => state);
    const dispatch = useDispatch();
    const formRef = useRef();
    const { t } = useTranslation();
    const [auth, setAuth] = useState(false);
    const [authorizing, setAuthorizing] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [data, setData] = useState({});
    const [tokenInfo, setTokenInfo] = useState(null);
    const [box,setBox] = useState(false)
    const [fromChainInfo, setFromChainInfo] = useState({});
    const [toChainInfo, setToChainInfo] = useState({});
    // const [fromChainId, setFromChainId] = useState(0);
    // const [toChainId, setToChainId] = useState(0);
    const [images1, setImages1] = useState(true);
    const [images2, setImages2] = useState(true);
    const [tokenBalanceOf, setTokenBalanceOf] = useState('0');

    const [tokenSelectVisible, setTokenSelectVisible] = useState(false);
    const [lang, setLang] = useState('')
    const [fee, setFee] = useState('0')
    const [mainSymbol, setMainSymbol] = useState('')

    // useEffect(()=>{
    //     var info = MockJSON.info || {};
    //     (info.data || []).forEach((token) => {
    //         if (!token.icon) {
    //             token.icon = 'https://crossing-resources.s3-ap-northeast-1.amazonaws.com/images/logo_default.png';
    //         }
    //         (token.data || []).forEach((chain) => {
    //             chain.tokenIcon = token.icon;
    //         })
    //     });
    //     setData(info);
    // },[])

    useEffect(()=>{
        request.query('api/bridge/queryalltoken.do').then((res) => {
            //console.log(res);
            var info = res.info || {};
            (info.data || []).forEach((token) => {
                if (!token.icon) {
                    token.icon = 'https://crossing-resources.s3-ap-northeast-1.amazonaws.com/images/logo_default.png';
                }
                (token.data || []).forEach((chain) => {
                    chain.tokenIcon = token.icon;
                })
            });
            setData(info);
        });
    }, [])

    useEffect(() => {
        setLang(getLangObject().value);
    }, []);

    useEffect(()=>{     
        if (data && data.data && data.data.length > 0) {
            var tokenName = tokenInfo ? tokenInfo.tokenName : Tools.getURLParams('tokenName');
            if (tokenName) {
                var info = (data.data || []).find((item) => (item.tokenName || '').toLowerCase() == tokenName.toLowerCase())
                if (info) {
                    setTokenInfo(info);    
                } else {
                    setTokenInfo(data.data[0]);
                }
            } else {
                setTokenInfo(data.data[0]);
            }
        } else {
            setTokenInfo(null);
        }
    }, [data])

    useEffect(()=>{
        if (tokenInfo) {
            // setFromChainInfo(tokenInfo.data[0]);
            // setToChainInfo(tokenInfo.data[1]);
            var fromId = Tools.getURLParams('fromChainId');
            var fromChainName = Tools.getURLParams('fromChainName');
            var toId = Tools.getURLParams('toChainId');
            var toChainName = Tools.getURLParams('toChainName');

            var fromChain = tokenInfo.data.find((item) => item.chainId == fromId && item.chainName == fromChainName);
            var toChain = tokenInfo.data.find((item) => item.chainId == toId && item.chainName == toChainName);

            if (fromChain && toChain) {
                // setFromChainId(fromChain.chainId);
                // setToChainId(toChain.chainId);
                setFromChainInfo(fromChain);
                setToChainInfo(toChain);
            } else {
                var fromChain = tokenInfo.data.find((item) => item.chainStatus == 1);
                if (fromChain) {
                    setFromChainInfo(fromChain);
                    var toChain = tokenInfo.data.find((item) => item.chainId != fromChain.chainId && item.chainName != fromChain.chainName && item.chainStatus == 1);
                    setToChainInfo(toChain);
                } else {
                    setFromChainInfo(tokenInfo.data[0]);
                    setToChainInfo(tokenInfo.data[1]);
                }
            }
        } else {
            setFromChainInfo({});
            setToChainInfo({});
            // setFromChainId(0);
            // setToChainId(0);
        }
        setTokenBalanceOf('0');
        setAuth(false);
    }, [tokenInfo])

    useEffect(()=>{
        setTokenBalanceOf('0');
        setAuth(false);
        actionWalletLogin({defaultChainId: fromChainInfo.chainId})(dispatch);
        if (fromChainInfo && fromChainInfo == toChainInfo) {
            var toChain = tokenInfo.data.find((item) => item.chainId != fromChainInfo.chainId && item.chainName != fromChainInfo.chainName && item.chainStatus == 1);
            setToChainInfo(toChain);
        }
        if (fromChainInfo && fromChainInfo.chainId) {
            if (fromChainInfo.chainId == 66) {
                setMainSymbol(fromChainInfo.chainName.includes('ok') ? 'OKT' : 'GT');
            } else {
                var chainConfig = ChainConfigInfo[fromChainInfo.chainId];
                setMainSymbol(chainConfig ? chainConfig.mainSymbol : '');
            }
        }
    }, [fromChainInfo])

    useEffect(()=>{
      if (wallet && wallet.active && tokenInfo && wallet.chainId == fromChainInfo.chainId) {
        getData();
      } else {
        setTokenBalanceOf('0');
        setAuth(false);
      }
    }, [wallet, tokenInfo, fromChainInfo])

    useEffect(()=>{
        var f = getFee().toString();
        console.log('fee: ' + f);
        setFee(f);
    }, [fromChainInfo, toChainInfo])

    const getData = async () => {
        if (!fromChainInfo || !fromChainInfo.tokenAddr) return;
        if (wallet.chainId == 66) {
            var web3 = new Web3(wallet.library.provider);
            var res = await web3.eth.getTransaction('0x041f04a5e6aa7beaf9b962d778e244a07f6b6bff6bfd04d17768c06cd75e311e');
            //res != null  -> gate-chain
            if (!((res && fromChainInfo.chainName.toLowerCase().includes('gate')) || (!res && fromChainInfo.chainName.toLowerCase().includes('ok')))) {
                return;
            }
        }
        //查询余额
        queryBalanceOf(wallet, fromChainInfo.tokenAddr, wallet.account).then((res) => {
            console.log('balanceOf: ' + res);
            setTokenBalanceOf(Tools.fromWei(res, fromChainInfo.tokenDecimal))
        });
        //查询授权额度
        getAllowance(wallet, fromChainInfo.tokenAddr, wallet.account, fromChainInfo.bridgeAddr).then((res) => {
            setAuth(res != 0)
        });
    }

    const onSelectTokenModal = ()=> {
        //if (!data || !data.data || data.data.length <= 1) return;
        if (!data || !data.data) return;
        setTokenSelectVisible(true);
    } 

    const onSelectToken = (tokenName, chainId)=> {
        var token = data.data.find((item) => item.tokenName == tokenName);
        if (token) {
            setTokenInfo(token);
            if (chainId) {
                var fromChain = tokenInfo.data.find((item) => item.chainId == chainId && item.chainStatus == 1);
                setFromChainInfo(fromChain);
            }
        }
        setTokenSelectVisible(false);
    }

    // const copyAddrFunc1 = ()=>{
    //     setTimeout(()=>{
    //         setImages1(true)
    //     },1000)
    //     setImages1(false)
    // }

    // const copyAddrFunc2 = ()=>{
    //     setTimeout(()=>{
    //         setImages2(true)
    //     },1000)
    //     setImages2(false)
    // }

    const connectWallet = ()=> {
        actionWalletModal({visible: true})(dispatch);
    }

    const getFee = () => {
        var fee = '0';
        if (data && tokenInfo) {
            var feeInfo = data.fee.find((item) => item.chainId == fromChainInfo.chainId && item.chainName == fromChainInfo.chainName);
            if (feeInfo) {
                feeInfo = feeInfo.data.find((item) => item.chainId == toChainInfo.chainId && item.chainName == toChainInfo.chainName);
                fee = feeInfo ? feeInfo.fee : '0';
                //fee = fromWei(fee);
            }
        }
        return fee;
    }

    const userAuthSubmit = async ()=>{
        if (wallet.chainId != fromChainInfo.chainId) {
            return message.error(t('switch_network', {p: fromChainInfo.chainName}));
        }
        if (wallet.chainId == 66) {
            var web3 = new Web3(wallet.library.provider);
            var res = await web3.eth.getTransaction('0x041f04a5e6aa7beaf9b962d778e244a07f6b6bff6bfd04d17768c06cd75e311e');
            console.log(res);
            //res != null  -> gate-chain
            if (!((res && fromChainInfo.chainName.toLowerCase().includes('gate')) || (!res && fromChainInfo.chainName.toLowerCase().includes('ok')))) {
                return message.error(t('switch_network', {p: fromChainInfo.chainName}));
            }
            return;
        }

        approve(wallet, fromChainInfo.tokenAddr, fromChainInfo.bridgeAddr)
        .on('error', function (error) {})
        .on('transactionHash', function (hash) {
            //process
            setAuthorizing(true);
        })
        .on('receipt', (receipt) => {
            setAuth(true);
            setAuthorizing(false);
        });
    }

    const onAppear = async ()=>{
        if (wallet.chainId != fromChainInfo.chainId) {
            return message.error(t('switch_network', {p: fromChainInfo.chainName}));
        }
        if (wallet.chainId == 66) {
            var web3 = new Web3(wallet.library.provider);
            //能查到这笔交易说明是gate链
            var res = await web3.eth.getTransaction('0x041f04a5e6aa7beaf9b962d778e244a07f6b6bff6bfd04d17768c06cd75e311e');
            console.log(res);
            //res != null  -> gate-chain
            if (!((res && fromChainInfo.chainName.toLowerCase().includes('gate')) || (!res && fromChainInfo.chainName.toLowerCase().includes('ok')))) {
                return message.error(t('switch_network', {p: fromChainInfo.chainName}));
            }
        }
        const {toAccount, quantity} = formRef.current.getFormData();
        if (!toAccount) {
            return message.error(t('input_address_message'));
        }
        if (!quantity) {
            return message.error(t('input_amount_message'));
        }
        if (quantity == 0) {
            return message.error(t('input_amount_message'));
        }
        if (parseFloat(quantity) > parseFloat(tokenBalanceOf)) {
            return message.error(t('insufficient_balance'));
        }
         deposit(wallet, fromChainInfo.bridgeAddr, fromChainInfo.tokenAddr, toChainInfo.bridgeAddr, toAccount, Tools.toWei(quantity, fromChainInfo.tokenDecimal), toWei(fee))
        .on('error', function (error) {})
        .on('transactionHash', function (hash) {
            message.success(t('transaction_submitted'));
            //process
            setSubmitting(true);
        })
        .on('receipt', (receipt) => {
            getData();
            setSubmitting(false);
        });
    }

    // var fromChainInfo;
    // var toChainInfo;
    // var fee = getFee();
    // var chainConfig;
    // if (data && tokenInfo) {
    //     fromChainInfo = tokenInfo.data.find((item) => item.chainId == fromChainId);
    //     toChainInfo = tokenInfo.data.find((item) => item.chainId == toChainId);
    //     chainConfig = ChainConfigInfo[fromChainId];
    // }
    const SelNo = () => {
        message.info("Coming Soon");
    }
    return (
        <div className="have_banner">
        <div className="mobile_banner">
            <div className="mobile_banner_top">
                <img src="/images/ic_hanbao.png" alt="" onClick={()=>setBox(!box)}/>
                <div className="select_box">
                    <div className="select_one">
                        Token
                    </div>
                    <div className="select_two" onClick={()=>{SelNo()}}>
                        NFT
                    </div>
                </div>
                
            </div>
            <div className={box ? "box" : "box have"}>
                <div className="box_list">
                    <a target='_blank' href="https://forms.gle/B5DqJngYYRVdRy1t6">{t('token_submit')}</a>
                </div>
                {/* <div className="box_list">
                    English
                </div>
                <div className="box_list">
                    中文
                </div> */}
                {langList.map((v,i)=>{
                    return (
                        <div className="box_list" key={v.value} value={v.value} onClick={e=>{
                            setLang(v.value);
                            switchLang(v.value);
                        }}>
                            {v.label}
                        </div>
                    )
                })}
            </div>
        </div>
        <div className="mobile_logo">
            <img src="/images/logo_crossing_black.png" alt=""/>
        </div>
        <div className="newHome">
            <div className='homePage'>
                <>
                    <div className="container crossChain">
                        {/* <div className="banner_img">
                            <img src="/images/cross/img_crosschain.png" alt=""/>
                        </div> */}
                        <div className="sel_fat">
                            <div className="select_box">
                                <div className="select_one">
                                    Token
                                </div>
                                <div className="select_two" onClick={()=>{SelNo()}}>
                                    NFT
                                </div>
                            </div>
                        </div>
                        
                        <div className="mobile_box">
                            <div className="tokenType">
                                <p className="font14">{t('token_label')}</p>
                                <div className="token_sel" onClick={onSelectTokenModal}>
                                    <div className="token_sel_div">
                                        <img src={tokenInfo ? tokenInfo.icon : ''} alt=""/>
                                        <span>{tokenInfo ? tokenInfo.tokenName : ''}</span>
                                    </div>
                                    
                                    {/* {data && data.data && data.data.length > 1 && <DownOutlined />} */}
                                    <DownOutlined />
                                    {/* <img src="/images/cross/ic_xialaarrow.png" style={{width:"18px"}} alt=""/> */}
                                </div>
                            </div>
                            <div className="change_chain">
                                <div className="chain_first">
                                    <span className="change_chain_text">{t('from_label')}</span>
                                    <Select 
                                        fromchainid={fromChainInfo.chainId} 
                                        fromchainname={fromChainInfo.chainName} 
                                        tochainid={toChainInfo.chainId} 
                                        tochainname={toChainInfo.chainName}
                                        value={fromChainInfo.chainName + fromChainInfo.chainId} 
                                        onChange={chainUID => {
                                            var chain = tokenInfo.data.find((item) => (item.chainName + item.chainId) == chainUID );
                                            if (chain == toChainInfo) {
                                                setToChainInfo(fromChainInfo);
                                            }
                                            setFromChainInfo(chain);
                                    }} style={{ width: "100%" }} dropdownClassName="list">
                                        {(tokenInfo ? (tokenInfo.data || []) : []).map((item) => (
                                            <Option key={item.chainName + item.chainId} value={item.chainName + item.chainId} disabled={item.chainStatus != 1}>
                                                <ChainInfoView info={item} />
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="chain_img">
                                    <img src="/images/cross/right-small.png" alt=""/>
                                </div>
                                <div className="chain_second">
                                    <span className="change_chain_text">{t('to_label')}</span><br/>
                                    <Select 
                                    fromchainid={fromChainInfo.chainId} 
                                    fromchainname={fromChainInfo.chainName} 
                                    tochainid={toChainInfo.chainId} 
                                    tochainname={toChainInfo.chainName}
                                    value={toChainInfo.chainName + toChainInfo.chainId} 
                                    onChange={chainUID => {
                                        var chain = tokenInfo.data.find((item) => (item.chainName + item.chainId) == chainUID );
                                        if (chain == fromChainInfo) {
                                            setFromChainInfo(toChainInfo);
                                        }
                                        setToChainInfo(chain);
                                    }} style={{ width: "100%" }} dropdownClassName="list">
                                        {(tokenInfo ? (tokenInfo.data || []) : []).map((item) => (
                                            <Option key={item.chainName + item.chainId} value={item.chainName + item.chainId} disabled={item.chainStatus != 1}>
                                                <ChainInfoView info={item} />
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        
                        {/* <div className="Addr_chain">
                            {fromChainInfo && <div className="Addr_chain-top">
                                <h3>{tokenInfo.tokenName}-{fromChainInfo.chainName} Address:</h3>
                                <div className="addr_a">
                                    <span>
                                        {fromChainInfo.tokenAddr}
                                    </span>
                                    <div className="img">
                                        {
                                            images1
                                            ?
                                                <CopyToClipboard text={fromChainInfo.tokenAddr} onCopy={() => copyAddrFunc1()}>
                                                    <img src="/images/cross/ic_copy.png" alt=""/>
                                                </CopyToClipboard>
                                            : <img src="/images/ic_copied.png" alt=""/>
                                        }
                                    </div>
                                </div>
                            </div>}
                            {toChainInfo && <div className="Addr_chain-bottom">
                            <h3>{tokenInfo.tokenName}-{toChainInfo.chainName} Address:</h3>
                                <div className="addr_a">
                                    <span>
                                    {toChainInfo.tokenAddr}
                                    </span>
                                    <div className="img">
                                        {
                                            images2
                                            ?
                                                <CopyToClipboard text={toChainInfo.tokenAddr} onCopy={() => copyAddrFunc2()}>
                                                    <img src="/images/cross/ic_copy.png" alt=""/>
                                                </CopyToClipboard>
                                            : <img src="/images/ic_copied.png" alt=""/>
                                        }
                                    </div>
                                    
                                </div>
                            </div>}
                        </div> */}
                        <div className="form">
                            <div className="form_top">
                                <TransferForm ref={formRef} decimals={fromChainInfo ? fromChainInfo.tokenDecimal : 18} account={wallet.account} active={wallet.active} tenBalance={tokenBalanceOf}></TransferForm>
                                <div className="amount">
                                    <div>
                                        <p className="label">{t('available_label')} &nbsp;</p>
                                    </div>
                                    <div>
                                        <div className="text">{wallet.active && tokenInfo && wallet.chainId == fromChainInfo.chainId ? `${tokenBalanceOf} ${tokenInfo.tokenName}` : '--'}</div>
                                    </div>
                                </div>
                    
                                <div className="tradingFee">
                                    <div>
                                        <p className="label">{t('fee_label')} &nbsp;</p>
                                    </div>
                                    <div>
                                        <div className="text">{fee || '--'} {mainSymbol}</div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            
                            </div>
                            <Row>
                                {/* 链接钱包 */}
                                <Col span={24}>
                                    {(!wallet || !wallet.active) && <button className="btn-yellow" onClick={() => connectWallet()}>{t('connect_wallet')}</button>}
                                    {wallet && wallet.active && !auth && !authorizing && <button className="btn-yellow" onClick={() => userAuthSubmit()}>{t('authorize')}</button>}
                                    {wallet && wallet.active && !auth && authorizing && <button className="btn-yellow disable">{t('authorizing')}</button>}
                                    {wallet && wallet.active && auth && submitting && <button className="btn-yellow disable">{t('swaping')}</button>}
                                    {wallet && wallet.active && auth && !submitting && <button className="btn-yellow" onClick={() => onAppear()}>{t('confirm')}</button>}
                                    <p className="rowText">{t('risk_hint')}</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <CrossModal visible={tokenSelectVisible} data={data.data} onCancel={()=> setTokenSelectVisible(false)} onSelectToken={onSelectToken}/>
                    
                </>
            </div>
            <div className='tipTitle'>
                <p>{t('warning_hint1')}</p>
                <p>{t('warning_hint2')}</p>
            </div>
        </div>
        <div className="banner" style={{background:"url('/images/banner.png')",backgroundSize:"cover"}}>
            <a href="https://forms.gle/B5DqJngYYRVdRy1t6" target="_blank" className="run">{t('token_submit')}</a>
            <HeaderLang/>
            <div className="logo_corss"> 
                <img src="/images/logo_crossing_white.png" alt=""/>
            </div>
        </div>
        </div>
    );
};

function ChainInfoView(props) {
    const { t } = useTranslation();

    const { info } = props;
    //console.log(info);
    return (
        <div className="sel_con">
            <img src={info.icon} alt=""/>
            <div>
                <span>{info ? info.chainName : '--'}</span>
                {info.chainStatus == 1 && <span>{t('available_label')} {info ? Tools.fmtToExponential(Tools.numFmt(info.tokenBalance, info.tokenDecimal)) : '--'}</span>}
                {info.chainStatus != 1 && <span>Coming Soon</span>}
            </div>
        </div>
    );
}
export default Exchange;
