/* eslint-disable import/no-anonymous-default-export */

export default {
    1 : {
        type: "ETH",
        env: "production",
        mainSymbol: "ETH",
        networkName: "ETH",
        rpcUrl: 'https://mainnet.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://etherscan.io/'
    },
    3 : {
        type: "ETH",    
        env: "test",
        mainSymbol: "ETH",
        networkName: "Ropsten",
        rpcUrl: 'https://ropsten.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://ropsten.etherscan.io/'
    },
    4 : {
        type: "ETH",
        env: "test",
        mainSymbol: "ETH",
        networkName: "Rinkeby",
        rpcUrl: 'https://rinkeby.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://rinkeby.etherscan.io/'
    },
    5 : {
        type: "ETH",
        env: "test",
        mainSymbol: "ETH",
        networkName: "Goerli",
        rpcUrl: 'https://goerli.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://goerli.etherscan.io/'
    },
    42 : {
        type: "ETH",
        env: "test",
        mainSymbol: "ETH",
        networkName: "Kovan",
        rpcUrl: 'https://kovan.infura.io/v3/34c59a94d8ef460086c55841531e0b67',
        blockTime: 15000,
        explorerName: 'Etherscan',
        explorerUrl: 'https://kovan.etherscan.io/'
    },
    56 : {
        type: "BSC",
        env: "production",
        mainSymbol: "BNB",
        networkName: "BSC",
        rpcUrl: 'https://bsc-dataseed1.binance.org/',
        blockTime: 3000,
        explorerName: 'Bscscan',
        explorerUrl: 'https://bscscan.com/'
    },
    97 : {
        type: "BSC",
        env: "test",
        mainSymbol: "BNB",
        networkName: "BSC Test",
        rpcUrl: 'https://testnet.bscscan.com/',
        blockTime: 3000,
        explorerName: 'Bscscan',
        explorerUrl: 'https://testnet.bscscan.com/'
    },
    128 : {
        type: 'HC',
        env: "production",
        mainSymbol: "HT",
        networkName: "HECO",
        rpcUrl: 'https://http-mainnet-node.huobichain.com/', 
        blockTime: 3000,
        explorerName: 'Hecoinfo',
        explorerUrl: 'https://hecoinfo.com/'
    },
    256 : {
        type: 'HC',
        mainSymbol: "HT",
        networkName: "HC Test",
        rpcUrl: 'https://http-testnet.hecochain.com/', 
        blockTime: 3000,
        explorerName: 'Hecoinfo',
        explorerUrl: 'https://testnet.hecoinfo.com/'
    },
    1337 : {
        type: "Gate",
        env: "test",
        mainSymbol: "GT",
        networkName: "Gatetest",
        rpcUrl: 'http://meteora.gatenode.cc:6060',
        blockTime: 3000,
        explorerName: 'Gatescan',
        explorerUrl: 'https://gatescan.org/meteora/'
    },
    66 : {
        type: "Gate",
        env: "production",
        mainSymbol: "GT",
        networkName: "Gate",
        rpcUrl: 'https://evm.gatenode.cc/',
        blockTime: 3000,
        explorerName: 'Gatescan',
        explorerUrl: 'https://gatescan.org/'
    },
    65 : {
        type: "OkexChain",
        env: "test",
        mainSymbol: "OKT",
        networkName: "OkexChainTestNet",
        rpcUrl: 'https://exchaintest.okexcn.com',
        blockTime: 3000,
        explorerName: 'Okscan',
        explorerUrl: 'https://www.oklink.com/okexchain-test/'
    },
    // 1337 : {
    //     mainSymbol: "ETH",
    //     networkName: "Private Chain",
    //     rpcUrl: 'http://127.0.0.1:9545/',
    //     blockTime: 3000,
    //     explorerName: 'Etherscan',
    //     explorerUrl: ''
    // },
};
