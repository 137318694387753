import React, { useContext, useEffect, useImperativeHandle,forwardRef } from 'react';
import { Input, Form, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';

import '../style.scss';
import * as Tool from '../../../utils/Tools';
// import AppContext from '@/state';


const TransferForm = (props,ref) => {
    const { t } = useTranslation();

    // const appContext = useContext(AppContext);
    const [form] = Form.useForm();

    useImperativeHandle(ref,()=>(
        {
            getFormData,
        }
    ))

    useEffect(() => {
        if(props.account){
            form.setFieldsValue({fromAccount: props.account})
        }else{
            form.setFieldsValue({
                fromAccount:t(['not_connect_wallet_message', '']),
                toAccount: '',
                quantity: '',
            })
        }
    });

    function getFormData(){
        return form.getFieldsValue();
    }

    // 从from中复制钱包地址到to
    function copyWalletAddress(){
        if(!props.active){
            message.error(t('not_connect_wallet_message'));
            return 0;
        }
        form.setFieldsValue({
            toAccount: form.getFieldValue("fromAccount")
        })

    }

    // 选择最大数量
    function maxQuantity(){
        if(!props.active){
            message.error(t('not_connect_wallet_message'));
            return 0;
        }
        form.setFieldsValue({
            quantity: Tool.numFmt(props.tenBalance || 0, props.decimals || 18)
        })
    }


    return (
        <Form name="form" form={form}
            initialValues={{
                fromAccount: props.account,
            }}>
            {/* <Row>
                <Col span={4}>
                    <p className="label">{t(['transfer.userForm.from', ''])} : </p>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="fromAccount">
                        <Input disabled={true} type="text"  placeholder={t(['transfer.message.walletConnect', ''])} />
                    </Form.Item>
                    {
                        props.active ? null :
                        <span className="linkBtn" onClick={() => appContext.toggleWalletVisible(true)}>
                            {t(['transfer.userForm.connectWallet'])}
                        </span>
                    }
                </Col>
            </Row> */}
            <Row>
                <Col span={24}>
                    <p className="label">{t('address_label')}</p>
                </Col>
            </Row>
            <Row>
                
                <Col span={24}>
                    <Form.Item name="toAccount">
                        <Input type="text" placeholder={t('wallet_address_hint')} />
                    </Form.Item>
                    <span className="upperBtn" onClick={()=>copyWalletAddress()}>
                        {t('same_address')}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <p className="label">{t('amount_label')}</p>
                </Col>
            </Row>
            <Row>   
                
                <Col span={24}>
                    <Form.Item name="quantity">
                        <Input type="number" placeholder={t('amount_hint')}
                        onChange={
                            ()=>{
                                var num = form.getFieldValue('quantity') > 200000 ? 200000 : form.getFieldValue('quantity')
                                form.setFieldsValue({
                                    quantity: Tool.numFmt(num, props.decimals || 18)
                                })
                            }
                        } />
                    </Form.Item>
                    <span className="upperBtn" onClick={e=>maxQuantity()}>
                        Max
                    </span>
                </Col>
            </Row>
        </Form>
    );
};

export default forwardRef(TransferForm);
