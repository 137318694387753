import { combineReducers } from 'redux';

import wallet from './wallet';
import modal from './modal';

const rootReducer = combineReducers({
    wallet,
    modal,
})

export default rootReducer;
