import md5 from 'md5';

class Common{

    // loading
    loading(v){
        if(v !== false){
            let mask = "<div class='loading'><div class='loader-07'></div></div>";
            document.getElementById("mask").innerHTML=mask;
            return;
        }
        document.getElementById("mask").innerHTML='';
    }

    // 去除左右空格
    trimSpace(v){
        v = v+'';
        return v.replace(/\s+/g,'');
    }

    // 补前置0
    fillZore(v){
        return v >= 10 ? v+'' : '0' + v;
    }

    // 是否为数字  type : float or int
    isNumType(n,type){
        var regInt = /^[0-9]\d{0,}$/;
        var regFloat = /(^[0-9]\d{0,}$)|(^[0-9]\d{0,}\.[0-9]{1,}$)/;
        if(type === 'float'){
            return regFloat.test(n);
        }
        if(type === 'int'){
            return regInt.test(n);
        }
        return NaN;
    }

    // 设置localStorage
	storage(key,value){
        if(arguments.length===1){
            return localStorage.getItem(key);
        }
        if(typeof value==='string' && value.length===0){
			return localStorage.removeItem(key);
		}
        return localStorage.setItem(key, value);
    }

    // 从localStorage中存取json对象
    storageJson(key,value){

        if(arguments.length===1){
            let info = JSON.parse(localStorage.getItem(key));
            return info || {};
        }

        if(typeof value === 'string' && value === ''){
            return localStorage.removeItem(key);
		}

        if(typeof value === 'object'){
            return localStorage.setItem(key,JSON.stringify(value));
        }
    }

    // 设置sessionStorage
	session(key,value){
        if(arguments.length===1){
            return sessionStorage.getItem(key);
        }
        if(typeof value==='string' && value.length===0){
			return sessionStorage.removeItem(key);
		}
        return sessionStorage.setItem(key, value);

    }

    // 从sessionStorage中存取json对象
    sessionJson(key,value){

        if(arguments.length===1){
            let info = JSON.parse(sessionStorage.getItem(key));
            return info || {};
        }

        if(typeof value === 'string' && value === ''){
            return sessionStorage.removeItem(key);
		}

        if(typeof value === 'object'){
            return sessionStorage.setItem(key,JSON.stringify(value));
        }
    }

    // 日期格式化 (temp fn)
    timestampToUTC(addTime){
        let toStamp = Date.parse(new Date());
        toStamp += addTime;
        const date = new Date(toStamp);

        let y = this.fillZore(date.getFullYear());
        let m = this.fillZore(date.getMonth() + 1);
        let d = this.fillZore(date.getDate());
        let h = this.fillZore(date.getHours());
        let i = this.fillZore(date.getMinutes());
        let s = this.fillZore(date.getSeconds());

        return m+'.'+d+' '+h+':'+i;
    }

    // building 64b string []
    random66string(){
        const str = md5(new Date().getTime() + Math.random());
        return "0x"+md5(str)+str;
    }

    // 返回当前页状态
    getHistoryInfo(listTab){
        let fixed = "0";
        listTab.forEach((val,i)=>{
            if(window.location.pathname.indexOf(val.link)>=0){
                fixed = val.value;
            }
        })
        return fixed;
    }

}


export default new Common();

