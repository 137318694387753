import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

import { getLangObject } from './langUtil';

i18n
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            loadPath: `/locales/{{lng}}.json`
        },
        fallbackLng: getLangObject().value,
        debug: false,
        react: { useSuspense: false, wait: true },
        // keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;


